import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BlogPostService } from './blog-post.service';
import { Observable } from 'rxjs';
import { TokenStorage } from './token.storage';
import { Property } from './model/property';
import { ApiService } from './api.service';
import { Router } from '@angular/router';

declare var $: any; // Declare jQuery to avoid TypeScript errors

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'hotelWebsite';
  blogPost: any[] = [];
  blogPosts$: Observable<any> | undefined;
  property: any;
  businessUser:any ;
  googleReviews: any;
  chunkedReviews: any;
  viewportScroller: any;
  dynamicText: any;
  dynamicPropertyId: any;
  dynamicCity: any;
  dynamicStreetName: any;
  dynamicStreetNumber: any;
  dynamicLocality: any;
  dynamicCountryName: any;
  propertyusername: string;

  constructor(
    public router: Router,
    private contentfulService: BlogPostService,
    private tokenStorage: TokenStorage,
    // private renderer: Renderer2,
    private apiService: ApiService ,// Inject ApiService (Assuming ApiService exists and is imported)
    private renderer: Renderer2,
    private cdrf :ChangeDetectorRef
    // private apiService:ApiService,
  ) { }

  ngOnInit() {

    this.blogPosts$ = this.contentfulService.getAllEntries();

    this.businessUser = this.tokenStorage.getProperty();
    this.getProperty();
    this.getGoogleReview();
    this.changeTheme( this.businessUser.primaryColor,
      this.businessUser.secondaryColor,
      this.businessUser.tertiaryColor)




      window.addEventListener('df-request-sent', (event) => {
        this.propertyusername = this.businessUser.name;
        const chatbotElement = document.getElementById('chatbot');
        // ... rest of your code to set chat title
      chatbotElement.setAttribute('chat-title', this.propertyusername);

      chatbotElement.setAttribute('chat-title-icon', this.businessUser.logoUrl);
        const propertyId = this.businessUser.id;
        const propertyName = this.businessUser.name;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const currentTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const dataToSend = {
          propertyId: propertyId,
          propertyName:propertyName,
          currentDate:currentTimeString,
        };
        fetch('https://chatbot.uat.bookone.io/api/website/receivePayload', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSend),
        })
        .then(response => response.json())
        .catch(error => console.error('Error:', error));
      });
  }


  carouselItems = [
    { imgSrc: 'assets/images/Bhor Ghat.jpg', title: 'Bhor Ghat' },
    { imgSrc: 'assets/images/Blackwaters of Pali Bhutivali Dam.jpeg', title: 'Blackwaters of Pali Bhutivali Dam' },
    { imgSrc: 'assets/images/hq720.jpg', title: 'Jain Temple' },
    { imgSrc: 'assets/images/Kothaligad.jpeg', title: 'Kothaligad' },
    { imgSrc: 'assets/images/ND Studio Karjat.avif', title: 'ND Studio Karjat' }
  ];

  chunkReviews() {
    this.chunkedReviews =[]
    const chunkSize = 2;
    for (let i = 0; i < this.googleReviews.length; i += chunkSize) {
      this.chunkedReviews.push(this.googleReviews.slice(i, i + chunkSize));
    }
  }
  getWhatsappShareUrl() {
    const baseUrl = 'https://api.whatsapp.com/send';
    const phoneNumber = this.businessUser?.whatsApp; // Assuming whatsApp is part of businessUser

    // Assign dynamic properties
    this.dynamicText = this.businessUser?.name;
    this.dynamicPropertyId = this.businessUser?.id;
    this.dynamicCity = this.businessUser?.address?.city;
    this.dynamicStreetName = this.businessUser?.address?.streetName;
    this.dynamicStreetNumber = this.businessUser?.address?.streetNumber;
    this.dynamicLocality = this.businessUser?.address?.locality;
    this.dynamicCountryName = this.businessUser?.address?.country;

    // Construct the message for WhatsApp
    const message = `*This is an Enquiry from :*Website: ${this.dynamicText || ''}`;

    // Encode the URL to ensure it's properly formatted for WhatsApp
    return `${baseUrl}?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;;
}


  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(
      (response) => {
        this.property = response.body; // Assign response body to `property`
         this.businessUser = response.body;
        this.tokenStorage.saveProperty(this.property); // Use `tokenStorage` instead of `token`
        this.cdrf.detectChanges();
        this.initializeSlideItems(); // Initialize slide items after fetching property details
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
          console.error('Error fetching property details:', error.message);
        }
      }
    );
  }

  ngAfterViewInit() {
    // Initialize Owl Carousel after view initialization
    this.getProperty();
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2
        },
        992: {
          items: 2
        }
      }
    });

    // Add click event listeners to "Read More" links
    const readMoreLinks = document.querySelectorAll('.read-more');

    readMoreLinks.forEach((link) => {
      this.renderer.listen(link, 'click', (event) => this.expandText(event));
    });
  }
  getGoogleReview() {
    this.apiService.getGoogleReview(environment.propertyId).subscribe(
      (response) => {
        this.googleReviews = response.body;
        this.cdrf.detectChanges();
        // this.chunkReviews();
    // console.log("this.googleReviews" + JSON.stringify(this.googleReviews))
      },

    );
  }
  changeTheme(primary: string, secondary: string, tertiary: string) {
    document.documentElement.style.setProperty('--primary', primary);

    document.documentElement.style.setProperty('--secondary', secondary);
    document.documentElement.style.setProperty('--tertiary', tertiary);
    document.documentElement.style.setProperty('--button-primary', tertiary);
    document.documentElement.style.setProperty(
      '--primary-gradient',
      'linear-gradient( 180deg, ' + tertiary + ', ' + secondary + ')'
    );
    document.documentElement.style.setProperty(
      '--secondary-gradient',
      'linear-gradient( 312deg, ' + primary + ', ' + secondary + ')'
    );
    document.documentElement.style.setProperty(
      '--secondary-one-gradient',
      'linear-gradient( 180deg, ' + primary + ', ' + secondary + ')'
    );

    document.documentElement.style.setProperty(
      '--third-gradient',
      'linear-gradient( 180deg, ' + primary + ', ' + secondary + ')'
    );
  }
  expandText(event: Event) {
    const target = event.target as HTMLElement;
    const parent = target.closest('.testimonial');
    if (!parent) return;

    const textContainer = parent.querySelector('.text-truncated');
    if (!textContainer) return;

    const fullText = textContainer.getAttribute('data-full-text');
    if (fullText) {
      textContainer.textContent = fullText;
      target.style.display = 'none';
    }
  }

  navigate() {
    const seoFriendlyName = this.tokenStorage.getProperty()?.seoFriendlyName || '';
    window.open(`https://bookone.io/${seoFriendlyName}?BookingEngine=true`, '_blank');
  }

  // Assuming initializeSlideItems is needed and defined somewhere else in your code
  initializeSlideItems() {
    // Add implementation for initializing slide items
  }

  scrollToSection(section: string): void {
    // First navigate to the section
    this.router.navigate([], { fragment: section }).then(() => {
      // Then force scroll to the section using ViewportScroller
      this.viewportScroller.scrollToAnchor(section);
    });
  }
}

export const APP_ID = environment.appId;
export const PROPERTY_ID = environment.propertyId;
